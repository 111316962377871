import Vue from 'vue'
import Vuex from 'vuex'
import {
  getContent,
  getSystems,
  getConstructionBySystemId,
  getRequiredIndex,
  getDrawing,
  getCalculationResult,
  getOptionalSystem, getPdf, getDocument, imageURL
} from '@/api'
import { sendMetrics } from '@/api/metrics'
import { saveAs } from 'file-saver'
import { v4 as uuidv4 } from 'uuid'
import { stepNumbers } from '@/utils/stepNumbers'
import _ from 'lodash'
import { CalcTypes } from '@/utils/types'
import { getReportStructure, getSortedArrayWithNested } from '@/utils/utils'

export function initStore(plugins) {
  const { i18n } = plugins
  const defaultTitle = i18n.messages[i18n.locale].message.store.sector
  const defaultConstructionType = i18n.messages[i18n.locale].message.store.defaultType
  const exportTitle = i18n.messages[i18n.locale].message.store.exportTitle

  let metricObject = null

  Vue.use(Vuex)
  const store = new Vuex.Store({
    state: {
      hash: null,
      TNTokenStatus: false,
      TNToken: null,
      isErrorLink: false,
      category: null,
      objectInfo: {
        link: '',
        result: null
      },
      pages: {
        wizard: {
          isLoading: true
        },
        result: {
          isLoading: false
        }
      },
      modal: {
        selectedSystem: null
      },
      activeSector: 0,
      sectors: [
        {
          id: null,
          name: `${defaultTitle} 1`,
          currentStep: 0,
          constructionType: {
            type: `${defaultConstructionType}`,
            buildingType: null,
            location: null,
            rating: null
          },
          base: {
            base: null,
            density: null,
            thickness: null
          },
          screed: {
            screed: null,
            thickness: null
          },
          facing: {
            facing: null,
            density: null,
            thickness: null,
            sheets: null
          },
          isolation: {
            isolation: null,
            thickness: null
          },
          indexes: {
            required: null,
            calculated: null,
            isTested: false
          },
          system: null,
          algorithm: CalcTypes.lite,
          image: null,
          optionalSystems: null,
          construction: null,
          structures: null,
          filling: null,
          isCalculated: false,
          errors: null
        }
      ],
      constructionTypes: [],
      defaultSector: {
        id: null,
        name: `${defaultTitle}`,
        currentStep: 0,
        constructionType: {
          type: `${defaultConstructionType}`,
          buildingType: null,
          location: null,
          rating: null
        },
        base: {
          base: null,
          density: null,
          thickness: null
        },
        screed: {
          screed: null,
          thickness: null
        },
        facing: {
          facing: null,
          density: null,
          thickness: null,
          sheets: null
        },
        isolation: {
          isolation: null,
          thickness: null
        },
        indexes: {
          required: null,
          calculated: null
        },
        system: null,
        algorithm: CalcTypes.lite,
        optionalSystems: null,
        construction: null,
        structures: null,
        filling: null,
        isCalculated: false,
        errors: null
      }
    },
    getters: {
      getTNToken: (state) => state.TNToken,
      getWizardLoadingStatus: (state) => state.pages.wizard.isLoading,
      getSectors: (state) => () => state.sectors,
      getSector: (state) => (index) => state.sectors[index],
      getConstructionTypes: (state) => state.constructionTypes,
      getModalValues: (state) => state.modal
    },
    mutations: {
      UPDATE_CONTENT(state, constructionTypes) {
        state.constructionTypes = Object.freeze(getSortedArrayWithNested(constructionTypes.slice()))
        state.pages.wizard.isLoading = false
      },
      UPDATE_SYSTEM_LIST(state, payload) {
        const constructionTypes = state.constructionTypes.map((type) => {
          payload.forEach((item) => {
            if (item.constructionType === type.constructionType) {
              type.systems = item.systems.map((system) => {
                return {
                  ...system,
                  image: system.image ? `${imageURL}/uploads/${system.image}` : null
                }
              })
            }
          })
          return { ...type }
        })
        state.constructionTypes = Object.freeze(constructionTypes)
      },
      UPDATE_TN_TOKEN_STATUS: (state, TNTokenStatus) => {
        state.TNTokenStatus = TNTokenStatus
      },
      UPDATE_TN_TOKEN: (state, token) => {
        state.TNToken = token
      },
      UPDATE_SECTOR: (state, payload) => {
        if (payload.subproperty) {
          state.sectors[payload.index][payload.property] = {
            ...state.sectors[payload.index][payload.property],
            [payload.subproperty]: payload.value
          }

          return
        }

        state.sectors[payload.index][payload.property] = payload.value
      },
      DELETE_SECTOR: (state, payload) => {
        state.sectors.splice(payload.index, 1)
      },
      ADD_SECTORS: (state) => {
        const newSector = { ...state.defaultSector }
        newSector.id = uuidv4()
        newSector.name = `${defaultTitle} ${state.sectors.length + 1}`
        state.sectors.push(newSector)
      },
      UPDATE_ACTIVE_SECTOR: (state, payload) => {
        state.activeSector = payload
      },
      UPDATE_MODAL: (state, payload) => {
        state.modal[payload.property] = payload.value
      }
    },
    actions: {
      getContent({ commit, state }) {
        getContent().then((response) => {
          if (state.sectors[0].id === null) state.sectors[0].id = uuidv4()

          commit('UPDATE_CONTENT', response.data)
          getSystems().then((response) => {
            commit('UPDATE_SYSTEM_LIST', response.data)
          })
        })
      },
      getConstructionBySystemId({ commit, state }, index) {
        const systemId = state.sectors[index].system?.id
        getConstructionBySystemId(systemId, state.sectors[index].algorithm)
          .then((response) => {
            commit('UPDATE_SECTOR', { index, property: 'structures', value: response.data })
          })
      },
      getRequiredIndexes({ state, commit }, index) {
        const sector = state.sectors[index]
        const data = {
          locationId: sector.constructionType.location.id,
          rating: sector.constructionType.rating
        }
        getRequiredIndex(data).then((response) => {
          commit('UPDATE_SECTOR', { index, property: 'indexes', subproperty: 'required', value: response.data })
        })
      },
      getDrawing({ state, commit }, index) {
        const sector = state.sectors[index]
        const data = {
          structureId: sector.base?.base?.id || null,
          screedId: sector.screed?.screed?.id || null,
          systemId: sector.filling?.id ?? sector.system.id,
          option: {
            qtySheet: sector.facing?.sheets ?? null,
            thickness: sector.isolation?.thickness ?? null
          }
        }

        getDrawing(data)
          .then((response) => {
            commit('UPDATE_SECTOR', { index, property: 'image', value: response.data ? `${imageURL}/uploads/${response.data}` : null })
          })
      },
      getOptionalSystem({ state, commit }, index, isException = false) {
        const sector = state.sectors[index]
        const withFilling = Boolean(sector.filling)

        const data = {
          systemId: withFilling ? sector.filling?.id : sector.system?.id,
          structure: {
            id: isException ? null : sector.base.base?.id,
            density: isException ? null : sector.base.density,
            thickness: isException ? null : sector.base.thickness
          },
          screed: {
            id: sector.screed.screed ? sector.screed.screed.id : null,
            density: null,
            thickness: sector.screed?.thickness ? (sector.screed?.thickness).toString() : null
          },
          location: {
            locationId: sector.constructionType.location?.id,
            rating: sector.constructionType?.rating
          }
        }

        getOptionalSystem(data).then((response) => {
          if (response.data) {
            const hasErrors = response.data.hasOwnProperty('rw')
            const property = hasErrors ? 'errors' : 'optionalSystems'
            const value = hasErrors
              ? {
                rw: { text: response.data.rw, needRender: Boolean(response.data.rw) },
                lnw: { text: response.data.lnw, needRender: Boolean(response.data.lnw) }
              }
              : response.data.map((construction) => {
                return {
                  ...construction,
                  image: construction.image ? `${imageURL}/uploads/${construction.image}` : null,
                  materials: construction.totalThickness
                    ? [ { title: 'Толщина конструкции', number: 0, thickness: construction.totalThickness }, ...construction.materials ]
                    : construction.materials
                }
              })

            commit('UPDATE_SECTOR', { index, property, value })
          }
        })
      },
      getCalculationResult({ state, commit }, index) {
        const sector = state.sectors[index]

        const data = {
          systemId: sector.system?.id,
          structure: {
            id: sector.facing.facing?.id || null,
            density: sector.facing.density || null,
            thickness: sector.facing.thickness || null,
            qtySheet: sector.facing.sheets || null
          },
          screed: {
            id: sector.isolation.isolation?.id || null,
            thickness: sector.isolation?.thickness ? (sector.isolation.thickness).toString() : null
          },
          location: {
            locationId: sector.constructionType.location?.id,
            rating: sector.constructionType?.rating
          }
        }

        const handleError = (error) => {
          commit('UPDATE_SECTOR', { index, property: 'errors', value: { rw: { needRender: true, text: error } } })
          commit('UPDATE_SECTOR', { index, property: 'isCalculated', value: false })
        }

        const handleResponse = (response) => {
          const wasDataForRequestActual = data.structure.density === sector.facing.density &&
            data.structure.thickness === sector.facing.thickness

          function getCalculatedRw() {
            if (!response) {
              return null
            }

            if (response?.isTested === undefined) {
              return response
            }

            commit('UPDATE_SECTOR', { index, property: 'indexes', subproperty: 'isTested', value: response.isTested })

            return response.rw
          }

          commit('UPDATE_SECTOR', {
            index,
            property: 'indexes',
            subproperty: 'calculated',
            value: { rw: getCalculatedRw(), lnw: null }
          })
          commit('UPDATE_SECTOR', { index, property: 'errors', value: null })
          commit('UPDATE_SECTOR', { index, property: 'isCalculated', value: wasDataForRequestActual })
        }

        getCalculationResult(data)
          .then((result) => {
            typeof result.data === 'string' || !result ? handleError(result.data) : handleResponse(result.data)
          })
          .catch(() => {
            handleError(null)
          })
      },
      getExportDocument({ state, commit }, payload) {
        function getRequest(data) {
          return payload.action === 'pdf' ? getPdf(data) : getDocument(data)
        }

        const requestData = {
          person: null,
          reports: state.sectors
            .filter(sector => sector.isCalculated)
            .map(sector => getReportStructure(sector))
        }

        getRequest(requestData).then((response) => {
          const file = new Blob([response.data], { type: `application/${payload.action}` })
          const fileName = `${exportTitle}.${payload.action}`
          saveAs(file, fileName)

          if (payload.action === 'pdf') {
            const fileURL = window.URL.createObjectURL(file)
            window.open(fileURL)
          }
        })
      },
      setCalculatedIndexes({ state, commit }, { index, payload }) {
        commit('UPDATE_SECTOR', { index, property: 'indexes', subproperty: 'calculated', value: payload })
      },
      setTestingStatus({ state, commit }, { index, payload }) {
        commit('UPDATE_SECTOR', { index, property: 'indexes', subproperty: 'isTested', value: payload })
      },
      async sendMetrics({ state }) {
        const data = state.sectors
          .filter((sector, index) => state.activeSector === index)
          .map((sector) => {
            return {
              id: sector.id,
              locationInfo: {
                locationId: sector.constructionType.location?.id ?? null,
                rating: sector.constructionType.rating
              },
              optionSystem: {
                lnw: sector.construction?.lnw ?? null,
                optionSystemId: sector?.construction?.id ?? null,
                rw: sector.construction?.rw ?? null
              },
              stepNumber: (sector.currentStep + 1).toString(),
              structure: {
                density: sector.base.density ?? sector.facing.density ?? 0,
                structureId: sector.base.base?.id ?? sector.facing.facing?.id,
                thickness: sector.base.thickness ?? sector.facing.thickness ?? 0,
                qtySheet: sector.facing.sheets ?? null
              },
              systemId: sector.system?.id ?? null,
              titleSection: sector.name
            }
          })

        const obj = { ...data[0], stepNumber: null }
        const idActiveSector = state.sectors[state.activeSector].id
        const sector = state.sectors[state.activeSector]

        if (metricObject === null) {
          metricObject = {}
          metricObject[`${idActiveSector}`] = { ...obj }
        }

        const isEqual = _.isEqual(metricObject[`${idActiveSector}`], obj)
        const noChangesAfterCalculating =
          (sector.isCalculated && sector.currentStep !== stepNumbers.result && isEqual) ||
          (sector.currentStep === stepNumbers.result && isEqual)

        if (noChangesAfterCalculating) {
          return
        }

        try {
          await sendMetrics(JSON.stringify(...data))
          metricObject[`${idActiveSector}`] = { ...obj, stepNumber: null }
        } catch (e) {}
      }
    }
  })

  return Promise.resolve({
    ...plugins,
    store
  })
}
