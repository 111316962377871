import { CalcTypes } from '@/utils/types'

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
}

export function encodeSVG(svg) {
  return svg.replace(/#/g, '%23').replace(/</g, '%3C').replace(/>/g, '%3E')
}

export const transparentFavIcon = encodeSVG(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 18"><path fill="none" d="M-1-1H23V19H-1z"/><path stroke="null" stroke-opacity="null" stroke-width="null" fill="transparent" d="M0-.7H22.1V18.1H0z"/></svg>`)

export function setInputWidth(element) {
  const length = element.getAttribute('placeholder')?.length
  element.setAttribute('size', length)
}

export function setContainerHeight() {
  const selectors = ['.header', '.nav', '.navigation__slider', '.tabs', '.wizard__wrapper', '.wizard__title', '.wizard-footer']
  const margins = ['marginTop', 'marginBottom']
  const paddings = ['paddingTop', 'paddingBottom']

  let height = 0

  selectors.forEach((selector) => {
    const element = document.querySelector(selector)
    const isContainer = selector === '.wizard__wrapper'
    const offsets = isContainer ? [...paddings, ...margins] : [...paddings]

    if (element) {
      offsets.forEach((offset) => {
        height += parseFloat(window.getComputedStyle(element)[offset])
      })
      height += !isContainer ? element.getBoundingClientRect().height : 0
    }
  })

  return `${window.innerHeight - height}px`
}

export const initialVisibleItems = 3

export const actions = {
  next: 'next',
  back: 'back',
  toStart: 'toStart',
  switchSector: 'switch',
  editSector: 'edit'
}

export function getReportStructure(sector) {
  return {
    title: sector.name,
    optionSystemId: sector.construction?.id || null,
    calc: {
      calcType: sector.algorithm,
      systemId: sector.system?.id,
      structure: {
        id: sector.base.base?.id ?? sector.facing.facing?.id ?? null,
        density: sector.base.density ?? sector.facing.density ?? 0,
        thickness: sector.algorithm === CalcTypes.lite ? sector.base.thickness || 0 : sector.facing.thickness || 0,
        qtySheet: sector.facing.sheets ?? null
      },
      screed: {
        id: sector.screed.screed?.id ?? sector.isolation.isolation?.id ?? null,
        thickness: sector.algorithm === CalcTypes.lite ? (sector.screed?.thickness)?.toString() || '0' : sector.isolation?.thickness || 0
      },
      location: {
        locationId: sector.constructionType.location.id,
        rating: sector.constructionType.rating
      }
    }
  }
}

export function getSortedArrayWithNested(objValue) {
  if (!Array.isArray(objValue)) return objValue

  objValue.sort((a, b) => (a.order || 0) - (b.order || 0))

  objValue.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (Array.isArray(item[key])) getSortedArrayWithNested(item[key])
    })
  })

  return objValue
}
